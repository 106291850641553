import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import styles from "./LandingPage.module.css";
import Typewriter from "typewriter-effect";

const gameImage1 = "https://i.ibb.co/5RjHTxQ/image.png";
const gameImage2 = "https://i.ibb.co/PTJ7yYB/image2.png";
const gameImage3 = "https://i.ibb.co/kmp7Sxs/image3.png";
const logoImage = "https://i.ibb.co/LZGQws3/favicon.jpg";

const LandingPage = () => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <img src={logoImage} alt="Logo" className={styles.logo} />
      </div>
      <div className={styles.content}>
        <h1 className={styles.title}>
          <Typewriter
            options={{
              strings: ["Welcome to Trade Empire"],
              autoStart: true,
              loop: true,
            }}
          />
        </h1>
        <p className={styles.description}>
          Explore the world of trading simulation game with Trade Empire. Join thousands of
          traders worldwide.
        </p>
        <div className={styles.buttons}>
          <Button
            component={Link}
            to="/register"
            variant="contained"
            color="primary"
            className={styles.button1}
          >
            Sign Up
          </Button>
          <Button
            component={Link}
            to="/login"
            variant="outlined"
            color="primary"
            className={styles.button2}
          >
            Login
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
