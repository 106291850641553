import React, { useEffect, useState, useContext } from 'react';
import { List, ListItem, TextField, Button, Typography, makeStyles, Paper, IconButton } from '@material-ui/core';
import axios from 'axios';
import SendIcon from '@material-ui/icons/Telegram';
import UserContext from "../../context/UserContext";

const useStyles = makeStyles((theme) => ({
  chatContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '70vh',
    padding: theme.spacing(2),
    backgroundColor: '#f5f5f5',
    borderRadius: theme.spacing(2),
    overflowY: 'auto', // Désactiver le défilement vertical
  },
  
  messageList: {
    marginBottom: theme.spacing(2),
  },
  messageItem: {
    marginBottom: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end', // Aligner les messages de l'utilisateur à droite par défaut
  },
  messageContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    maxWidth: '70%', // Limiter la largeur du message
    padding: theme.spacing(1),
    borderRadius: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  otherMessage: {
    alignSelf: 'flex-start', // Aligner les autres messages à gauche
    backgroundColor: '#e0e0e0', // Couleur de fond pour les autres messages
    borderRadius: theme.spacing(2),
    padding: theme.spacing(1),
    maxWidth: '70%', // Limiter la largeur du message
    marginBottom: theme.spacing(1),
  },
  userMessage: {
    alignSelf: 'flex-end', // Aligner les messages de l'utilisateur à droite
    backgroundColor: '#2196f3', // Couleur de fond pour les messages de l'utilisateur
    color: '#fff', // Couleur du texte pour les messages de l'utilisateur
    borderRadius: theme.spacing(2),
    padding: theme.spacing(1),
    maxWidth: '70%', // Limiter la largeur du message
    marginBottom: theme.spacing(1),
  },
  messageText: {
    marginLeft: theme.spacing(2),
    alignSelf: 'flex-start',
    marginBottom: theme.spacing(1),
    maxWidth: '300px',
    wordWrap: 'break-word',
  },
  timestamp: {
    alignSelf: 'flex-start',
    marginRight: theme.spacing(1),
  },
  playerBadge: {
    backgroundColor: '#ccc', // Couleur de fond pour le badge "PLAYER"
    color: '#000', // Couleur du texte pour le badge "PLAYER"
    padding: theme.spacing(0.3, 0.7), // Réduire la taille du badge
    marginRight: theme.spacing(1),
    borderRadius: '5px', // Ajouter des coins arrondis
    fontSize: '0.75rem', // Réduire la taille du texte dans le badge
  },
  adminBadge: {
    backgroundColor: '#ff0000', // Couleur de fond pour le badge "ADMIN"
    color: '#fff', // Couleur du texte pour le badge "ADMIN"
    padding: theme.spacing(0.3, 0.7), // Réduire la taille du badge
    marginRight: theme.spacing(1),
    borderRadius: '5px', // Ajouter des coins arrondis
    fontSize: '0.75rem', // Réduire la taille du texte dans le badge
  },
  username: {
    fontWeight: 'bold',
    marginRight: '8px',
    fontSize: '1rem', // Augmenter la taille du pseudo
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  textField: {
    flexGrow: 1,
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    width: '80%',
  },
}));

const ChatWindow = () => {
  const classes = useStyles();
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const { userData } = useContext(UserContext);

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const response = await axios.get('/api/messages');
        setMessages(response.data.slice(-50)); // Limiter l'affichage aux 50 derniers messages
      } catch (error) {
        console.error('Error fetching chat messages', error);
      }
    };

    fetchMessages();
  }, []);

  const sendMessage = async () => {
    if (!newMessage.trim()) {
      return; // Empêcher l'envoi d'un message vide
    }
    
    try {
      await axios.post('/api/messages', { content: newMessage.trim(), username: userData.user.username, teauthtoken: userData.user.teauthtoken});
      setNewMessage('');
      // Actualiser les messages après l'envoi du nouveau message
      const response = await axios.get('/api/messages');
      setMessages(response.data.slice(-50)); // Limiter l'affichage aux 50 derniers messages
    } catch (error) {
      console.error('Error sending message', error);
    }
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  const [error, setError] = useState(false);

  const handleChange = (event) => {
    const content = event.target.value;
    if (content.length <= 50) {
      setNewMessage(content);
      setError(false); // Réinitialiser l'erreur si la longueur est inférieure ou égale à 50
    } else {
      setError(true); // Définir une erreur si la longueur dépasse 50
    }
  };

  return (
    <div>
      <div className={classes.chatContainer}>
          <div className={classes.messageList}>
            <List>
              {messages.map((message, index) => (
                <ListItem key={index} className={classes.messageItem}>
                  <Paper className={`${classes.messageContent} ${message.username === userData.user.username ? classes.userMessage : classes.otherMessage}`}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Typography variant="body2" className={classes.timestamp}>{formatTimestamp(message.timestamp)}</Typography>
                      {['tradeempire', 'admin', 'ayoub31tls'].includes(message.username) ? (
                        <Typography variant="body2" className={classes.adminBadge}>ADMIN</Typography>
                      ) : (
                        <Typography variant="body2" className={classes.playerBadge}>PLAYER</Typography>
                      )}
                      <Typography variant="body2" className={classes.username}>{message.username}:</Typography>
                      <Typography variant="body2">{message.content}</Typography>
                    </div>
                  </Paper>
                </ListItem>
              ))}
            </List>
          </div>
    </div>
    <div className={classes.inputContainer}>
        <TextField
          value={newMessage}
          label="Enter your message"
          variant="outlined"
          className={classes.textField}
          onChange={handleChange}
          error={error} // Appliquer l'erreur au champ de texte
          helperText={error ? 'Message must be 50 characters or less' : null} //
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              sendMessage();
            }
          }}
        />
        <IconButton
          color="primary"
          onClick={sendMessage}
          disabled={!newMessage.trim()} // Désactiver le bouton d'envoi si aucun texte n'est saisi
        >
          <SendIcon />
        </IconButton>
      </div>
    </div>
  );
};

export default ChatWindow;
