import React, { useState, useEffect, useContext } from "react";
import { CircularProgress } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Title from "../Template/Title.jsx";
import styles from "./Dashboard.module.css";
import axios from "axios"; // Importez Axios pour effectuer des requêtes HTTP
import UserContext from "../../context/UserContext";

const Transactions = ({ transactions, fetchTransactions }) => {
  useEffect(() => {
    fetchTransactions();
  }, []);

  const roundNumber = (num) => {
    return Math.round((num + Number.EPSILON) * 100) / 100;
  };

  // Fonction pour trier les transactions par date de manière décroissante
  const sortByDateDesc = (a, b) => {
    return new Date(b.date) - new Date(a.date);
  };

  // Trier les transactions par date de la plus récente à la plus ancienne
  transactions.sort(sortByDateDesc);

  return (
    <React.Fragment>
      <div style={{ minHeight: "200px" }}>
        <Title>Transaction History</Title>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Ticker</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Total</TableCell>
              <TableCell>Type</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactions && transactions.length > 0 ? (
              transactions.map((transaction) => (
                <TableRow
                  key={transaction._id}
                >
                  <TableCell>
                    {new Date(transaction.date).toLocaleDateString()}
                  </TableCell>
                  <TableCell>{transaction.ticker}</TableCell>
                  <TableCell>{transaction.quantity}</TableCell>
                  <TableCell>
                    <span className={styles.greenText}>
                      ${roundNumber(transaction.price).toLocaleString()}
                    </span>
                  </TableCell>
                  <TableCell>
                    <span className={styles.greenText}>
                      ${roundNumber(transaction.quantity * transaction.price).toLocaleString()}
                    </span>
                  </TableCell>
                  <TableCell>
                    <span className={transaction.type === "BUY" ? styles.greenText : styles.redText}>
                      {transaction.type}
                    </span>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  No transactions found.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </React.Fragment>
  );
};

export default Transactions;
