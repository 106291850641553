import React, { useState, useContext } from "react";
import {
  Box,
  Typography,
  TextField,
  CssBaseline,
  Button,
  Card,
  CardContent,
  Grid,
  Link,
} from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import UserContext from "../../context/UserContext";
import Axios from "axios";
import config from "../../config/Config";

import styles from "./Auth.module.css";

const Login = () => {
  const navigate = useNavigate();
  const { setUserData } = useContext(UserContext);

  const [usernameOrEmail, setUsernameOrEmail] = useState("");
  const [usernameOrEmailError, setUsernameOrEmailError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const onChangeUsernameOrEmail = (e) => {
    const newUsernameOrEmail = e.target.value;
    setUsernameOrEmail(newUsernameOrEmail);
    setUsernameOrEmailError(""); // Effacer l'erreur lors de la modification du champ
  };

  const onChangePassword = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    setPasswordError(""); // Effacer l'erreur lors de la modification du champ
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const user = { usernameOrEmail, password };
    const url = config.base_url + "/api/auth/login";
    try {
      const loginRes = await Axios.post(url, user);

      if (loginRes.data.status === "fail") {
        // Afficher les messages d'erreur s'il y en a
        setUsernameOrEmailError(loginRes.data.message);
        setPasswordError(loginRes.data.message);
      } else {
        setUserData(loginRes.data);
        localStorage.setItem("auth-token", loginRes.data.token);
        navigate("/");
      }
    } catch (error) {
      console.error("Error logging in:", error);
    }
  };

  return (
    <div className={styles.background}>
      <CssBaseline />
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ minHeight: "100vh" }}
      >
        <Grid item xs={12} sm={8} md={6}>
          <Card className={styles.card}>
            <CardContent>
              <Typography variant="h5" component="h1" gutterBottom>
                Login
              </Typography>
              <form onSubmit={onSubmit} className={styles.form}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="usernameOrEmail"
                  label="Username or Email"
                  name="usernameOrEmail"
                  autoComplete="usernameOrEmail"
                  error={!!usernameOrEmailError} // Utiliser !! pour convertir en booléen
                  helperText={usernameOrEmailError}
                  value={usernameOrEmail}
                  onChange={onChangeUsernameOrEmail}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  error={!!passwordError} // Utiliser !! pour convertir en booléen
                  helperText={passwordError}
                  value={password}
                  onChange={onChangePassword}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={styles.submit}
                >
                  Login
                </Button>
              </form>
              <Grid container justify="center">
                <Grid item>
                  <Link href="/register" variant="body2">
                    Need an account? Sign up
                  </Link>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default Login;
