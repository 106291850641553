import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DashboardIcon from "@material-ui/icons/Dashboard";
import SearchIcon from "@material-ui/icons/Search";
import InfoIcon from "@material-ui/icons/Info";
import DiscordIcon from "@material-ui/icons/Launch";
import ChatIcon from '@material-ui/icons/Send';
import LeaderboardIcon from "@material-ui/icons/Equalizer";
import AppIcon from "@material-ui/icons/GetApp";

const Navbar = ({ currentPage, setCurrentPage }) => {
  const onNewsButtonClick = (e) => {
    e.preventDefault();
    setCurrentPage("news");
  };

  const onDashboardButtonClick = (e) => {
    e.preventDefault();
    setCurrentPage("dashboard");
  };

  const onSearchButtonClick = (e) => {
    e.preventDefault();
    setCurrentPage("search");
  };
  const onLeaderboardButtonClick = (e) => {
    e.preventDefault();
    setCurrentPage("leaderboard");
  };
  const onChatButtonClick = (e) => {
    e.preventDefault();
    setCurrentPage("chat");
  };

  const onDiscordButtonClick = () => {
    window.open("https://discord.trade-empire.cloud", "_blank");
  };
  
  const onAppDownloadButtonClick = () => {
    window.open("https://download.trade-empire.cloud", "_blank");
  };
  

  return (
    <div>
      <ListItem
        button
        selected={currentPage === "dashboard"}
        onClick={onDashboardButtonClick}
      >
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItem>
      <ListItem
        button
        selected={currentPage === "search"}
        onClick={onSearchButtonClick}
      >
        <ListItemIcon>
          <SearchIcon />
        </ListItemIcon>
        <ListItemText primary="Search" />
      </ListItem>
      <ListItem
        button
        selected={currentPage === "news"}
        onClick={onNewsButtonClick}
      >
        <ListItemIcon>
          <InfoIcon />
        </ListItemIcon>
        <ListItemText primary="Market News" />
      </ListItem>
      <ListItem
        button
        selected={currentPage === "leaderboard"}
        onClick={onLeaderboardButtonClick}
      >
        <ListItemIcon>
          <LeaderboardIcon />
        </ListItemIcon>
        <ListItemText primary="Leaderboard" />
      </ListItem>
      <ListItem
        button
        selected={currentPage === "chat"}
        onClick={onChatButtonClick}
      >
        <ListItemIcon>
          <ChatIcon />
        </ListItemIcon>
        <ListItemText primary="Global Chat" />
      </ListItem>
      {/* Discord Icon */}
      <ListItem button onClick={onDiscordButtonClick}>
        <ListItemIcon>
          <DiscordIcon />
        </ListItemIcon>
        <ListItemText primary="Discord" />
      </ListItem>
      {/* App Download Icon */}
      <ListItem button onClick={onAppDownloadButtonClick}>
        <ListItemIcon>
          <AppIcon />
        </ListItemIcon>
        <ListItemText primary="Download App" />
      </ListItem>
    </div>
  );
};

export default Navbar;
