import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { List, ListItemText, Typography, Container, makeStyles, Paper } from '@material-ui/core';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';

const useStyles = makeStyles((theme) => ({
  listItem: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    transition: 'background-color 0.3s', // Ajoute une transition pour l'effet de survol
    '&:hover': {
      backgroundColor: theme.palette.action.hover, // Change la couleur de fond au survol
    },
  },
  userNumber: {
    marginRight: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    fontSize: '1.5rem',
  },
  firstPlace: {
    color: '#FFD700', // Or
  },
  secondPlace: {
    color: '#C0C0C0', // Argent
  },
  thirdPlace: {
    color: '#CD7F32', // Bronze
  },
  username: {
    fontWeight: 'bold', // Rend le pseudo du joueur en gras
    fontSize: '1.1rem', // Taille de police légèrement plus grande
  },
  balance: {
    color: '#4CAF50', // Vert
    fontWeight: 'bold',
    marginLeft: 'auto',
  },
}));

const Leaderboard = () => {
  const classes = useStyles();
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get('/api/leaderboard');
        setUsers(response.data);
      } catch (error) {
        console.error('Error fetching leaderboard data', error);
      }
    };

    fetchUsers();
  }, []);

  const getPlaceClass = (index) => {
    if (index === 0) return classes.firstPlace;
    if (index === 1) return classes.secondPlace;
    if (index === 2) return classes.thirdPlace;
    return '';
  };

  return (
    <Container>
      {users.length === 0 ? (
        <Typography>No users found.</Typography>
      ) : (
        <List>
          {users.map((user, index) => (
            <Paper key={index} className={classes.listItem}>
              <div className={`${classes.userNumber} ${getPlaceClass(index)}`}>
                {index < 3 && <EmojiEventsIcon />}
                <Typography variant="h6" className={getPlaceClass(index)}>
                  {index + 1}
                </Typography>
              </div>
              <ListItemText
                primary={
                  <Typography variant="body1" className={classes.username}>
                    {user.username}
                  </Typography>
                }
                secondary={
                  <Typography variant="body2" className={classes.balance}>
                    ${user.balance}
                  </Typography>
                }
              />
            </Paper>
          ))}
        </List>
      )}
    </Container>
  );
};

export default Leaderboard;
