// src/components/ChatPage.js

import React from 'react';
import ChatWindow from './ChatWindow';

const ChatPage = () => {
  return (
    <div>
      <ChatWindow />
    </div>
  );
};

export default ChatPage;
